/* styles.css */
.custom-scrollbar {
    overflow-y: auto; /* Enable vertical scrolling */
  }
  
  .custom-scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 8px; /* Width of the scrollbar */
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar thumb */
    border-radius: 4px; /* Rounded corners */
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color on hover */
  }
  